import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

const saveUserToFirestore = async (examinee) => {
  try {
    const cleanedData = {
      fullName: examinee.fullName || "Unknown",
      email: examinee.email || "Unknown",
      questions: (examinee.questions || []).map((q) => ({
        question: q.question || q.randomText || "Unknown",
        answer: q.answer || "Not answered",
        point: q.point !== undefined ? q.point : 0,
      })),
    };

    const docRef = await addDoc(
      collection(firestore, "examinees"),
      cleanedData
    );
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export default saveUserToFirestore;
