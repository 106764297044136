import React from "react";
import "./style.scss";
import Animation from "../Animation/index";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleNavigateToExamReview = () => {
    navigate("/exam-review");
  };

  const navigateToMainPage = () => {
    navigate("/");
  };

  return (
    <div className="header">
      <div className="content-wrapper">
        <Animation />
        <h1 onClick={navigateToMainPage}>EXAM</h1>
        <div className="exam-review" onClick={handleNavigateToExamReview}>
          Review
        </div>
      </div>
    </div>
  );
};

export default Header;
