import React, { useState, useEffect, useContext } from "react";
import Modal from "./Modal";
import { ExamContext } from "components/Context/Context";

function Question({ getRandomPoints, data, onOpen, disabled, isRandom }) {
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { hasAnsweredNonRandom } = useContext(ExamContext);

  useEffect(() => {
    const savedState = localStorage.getItem(`question-${data.id}-isOpen`);
    if (savedState !== null) {
      setIsOpen(JSON.parse(savedState));
    }
  }, [data.id]);

  useEffect(() => {
    localStorage.setItem(`question-${data.id}-isOpen`, JSON.stringify(isOpen));
  }, [isOpen, data.id]);

  const open = () => {
    if (!disabled && (!isRandom || !hasAnsweredNonRandom)) {
      setShowModal(true);
      setIsOpen(true);
    }
  };

  const onConfirm = () => {
    setShowModal(false);
    onOpen(data.point);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const renderContent = () => {
    if (data.point === null) {
      return (
        <div>
          <div>?</div>
          <div style={{ fontSize: "12px" }}>
            {hasAnsweredNonRandom
              ? "Now random question is not available"
              : "Get random question"}
          </div>
        </div>
      );
    }
    return <div>{data.point}</div>;
  };

  return (
    <>
      <div
        onClick={open}
        className={`box box-${data.point} ${disabled ? "disabled" : ""} ${
          isOpen ? "open-box" : ""
        }`}
        style={{ opacity: isOpen ? 0.1 : 1 }}
      >
        {renderContent()}
      </div>

      {showModal && (
        <Modal
          message={`Are you sure you want to open this question with ${
            data.point || getRandomPoints
          } points?`}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        />
      )}
    </>
  );
}

export default Question;
