import React, { useState, useEffect, useContext } from "react";
import Question from "../Question";
import "./style.scss";
import QuestionModal from "../QuestionModal";
import Button from "../Button";
import ConfirmationModal from "../ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { questionDataFetch } from "../../helpers/questionDataFetch";
import { ExamContext } from "../Context/Context";
import saveUserToFirestore from "../../helpers/saveUserToFirestore";

const QuestionList = () => {
  const [tries, setTries] = useState(() => {
    const savedTries = localStorage.getItem("tries");
    return savedTries !== null ? JSON.parse(savedTries) : 20;
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState(() => {
    const savedAnswers = localStorage.getItem("answeredQuestions");
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [showFinishConfirmation, setShowFinishConfirmation] = useState(false);
  const [examFinished, setExamFinished] = useState(() => {
    const savedExamFinished = localStorage.getItem("examFinished");
    return savedExamFinished ? JSON.parse(savedExamFinished) : false;
  });
  const [questions, setQuestions] = useState([]);
  const [allNonRandomAnswered, setAllNonRandomAnswered] = useState(false);
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const {
    examFailed,
    setExamFailed,
    timeExpired,
    userAnswers,
    setUserAnswers,
    fullName,
    email,
    randomPoints,
    setRandomPoints,
    storedText,
  } = useContext(ExamContext);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const depQuestionsData = await questionDataFetch(departmentId);
        if (depQuestionsData) {
          const sortedQuestions = depQuestionsData.sort((a, b) => a.id - b.id);
          setQuestions(sortedQuestions);
        } else {
          setQuestions([]);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        setQuestions([]);
      }
    };
    fetchQuestions();
  }, [departmentId]);

  useEffect(() => {
    if (timeExpired) {
      setExamFailed(true);
    }
  }, [timeExpired, setExamFailed]);

  useEffect(() => {
    const storedPoints = localStorage.getItem("randomPoints");
    if (storedPoints) {
      setRandomPoints(parseInt(storedPoints, 10));
    } else {
      const points = Math.floor(Math.random() * 5) + 1;
      localStorage.setItem("randomPoints", points);
      setRandomPoints(points);
    }
  }, [setRandomPoints]);

  useEffect(() => {
    const nonRandomQuestions = questions.filter(
      (question) => question.point !== null
    );
    const answeredNonRandom = nonRandomQuestions.every((question) =>
      answeredQuestions.includes(question.id)
    );
    setAllNonRandomAnswered(answeredNonRandom);
  }, [answeredQuestions, questions]);

  useEffect(() => {
    localStorage.setItem(
      "answeredQuestions",
      JSON.stringify(answeredQuestions)
    );
  }, [answeredQuestions]);

  useEffect(() => {
    localStorage.setItem("tries", JSON.stringify(tries));
  }, [tries]);

  useEffect(() => {
    localStorage.setItem("examFinished", JSON.stringify(examFinished));
  }, [examFinished]);

  const handleFinishExam = async () => {
    const formattedAnswers = (questions || []).map((question) => ({
      question: question.text || storedText || "Unknown",
      answer: userAnswers?.[question.id] || "Not answered",
      point: question.point || randomPoints,
    }));

    const examineeData = {
      fullName,
      email,
      questions: formattedAnswers,
    };

    try {
      await saveUserToFirestore(examineeData);

      setExamFinished(true);
      setShowFinishConfirmation(false);
      navigate(examFailed ? "/failed-exam-finished" : "/exam-finished");
      if (examFailed) {
        localStorage.clear();
        sessionStorage.clear();
      }
    } catch (error) {
      console.error("Error finishing exam:", error);
    }

    localStorage.clear();
    sessionStorage.clear();
  };

  const handleCancelFinish = () => {
    setShowFinishConfirmation(false);
  };

  const openModalHandler = (question) => {
    if (!question) {
      console.error("Invalid question object:", question);
      return;
    }

    if (tries === 0 && !answeredQuestions.includes(question.id)) {
      return;
    }

    setModalQuestion({
      ...question,
      answer: userAnswers[question.id] || "",
    });
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setModalQuestion(null);
  };

  const onOpen = (userAnswer, randomQuestionText) => {
    if (!answeredQuestions.includes(modalQuestion.id)) {
      setAnsweredQuestions([...answeredQuestions, modalQuestion.id]);
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        [modalQuestion.id]: userAnswer,
      }));
      setTries((prevTries) => prevTries - 1);
    }

    if (randomQuestionText) {
      setModalQuestion((prevQuestion) => ({
        ...prevQuestion,
        randomText: randomQuestionText,
      }));
    }

    closeModal();
  };

  const filteredQuestions = questions.filter((question) =>
    question.point === null
      ? !answeredQuestions.includes(question.id) && !allNonRandomAnswered
      : true
  );

  return (
    <div className="question-list-wrapper">
      <div className="question-list">
        {filteredQuestions.map((question) => (
          <Question
            getRandomPoints={randomPoints}
            key={question.id}
            data={question}
            onOpen={() => openModalHandler(question)}
          />
        ))}
        {openModal && modalQuestion && (
          <QuestionModal
            data={modalQuestion}
            questions={filteredQuestions}
            onOpen={(userAnswer, randomQuestionText) =>
              onOpen(userAnswer, randomQuestionText)
            }
            onClose={closeModal}
          />
        )}
      </div>
      {!examFinished && (
        <Button
          className="button finish-button"
          name="Finish Exam"
          onClick={() => setShowFinishConfirmation(true)}
        />
      )}
      {showFinishConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to finish the exam?"
          onConfirm={handleFinishExam}
          onCancel={handleCancelFinish}
          confirmLabel="Yes"
          cancelLabel="No"
        />
      )}
    </div>
  );
};

export default QuestionList;
