import React, { createContext, useState, useEffect } from "react";

export const ExamContext = createContext();

export const ExamProvider = ({ children }) => {
  const [timeExpired, setTimeExpired] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [onOpen, setOnOpen] = useState(false);
  const [questionStatus, setQuestionStatus] = useState({});
  const [userAnswers, setUserAnswers] = useState({});
  const [examFailed, setExamFailed] = useState(false);
  const [randomPoints, setRandomPoints] = useState(0);
  const [storedText, setStoredText] = useState("");
  const [answeredNonRandomQuestions, setAnsweredNonRandomQuestions] = useState(
    new Set()
  );
  const [examinee, setExaminee] = useState({
    fullName: "",
    email: "",
    questions: [],
  });

  useEffect(() => {
    const savedStatus = JSON.parse(localStorage.getItem("questionStatus"));
    if (savedStatus) {
      setQuestionStatus(savedStatus);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("questionStatus", JSON.stringify(questionStatus));
  }, [questionStatus]);

  const updateQuestionStatus = (examineeId, questionId, status) => {
    setQuestionStatus((prevStatus) => ({
      ...prevStatus,
      [examineeId]: {
        ...prevStatus[examineeId],
        [questionId]: status,
      },
    }));
  };

  return (
    <ExamContext.Provider
      value={{
        timeExpired,
        setTimeExpired,
        onOpen,
        setOnOpen,
        examinee,
        setExaminee,
        fullName,
        setFullName,
        email,
        setEmail,
        questionStatus,
        updateQuestionStatus,
        examFailed,
        setExamFailed,
        userAnswers,
        setUserAnswers,
        randomPoints,
        setRandomPoints,
        storedText,
        setStoredText,
        answeredNonRandomQuestions,
        setAnsweredNonRandomQuestions,
      }}
    >
      {children}
    </ExamContext.Provider>
  );
};
