import React, { useState } from "react";
// import { Link } from "react-router-dom";
import ShareableLink from "../ShareLink";

function AdminPage() {
  // State to store the list of links
  const [links, setLinks] = useState([]);
  // State to store the new link input
  const [newLink, setNewLink] = useState("");

  // Function to handle adding a new link
  const addLink = () => {
    // Ensure the newLink is not empty
    if (newLink.trim() !== "") {
      // Add the new link to the links list
      setLinks([...links, newLink.trim()]);
      // Clear the input field
      setNewLink("");
    }
  };

  return (
    <div>
      <h1>Admin Page</h1>
      {/* Input field to enter a new link */}
      <input
        type="text"
        placeholder="Enter a new link"
        value={newLink}
        onChange={(e) => setNewLink(e.target.value)}
      />
      {/* Button to add the new link */}
      <button onClick={addLink}>Add Link</button>
      {/* Display the list of links */}
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            {/* Render the link */}
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </li>
        ))}
      </ul>
      {/* Render the ShareableLink component */}
      <ShareableLink />
    </div>
  );
}

export default AdminPage;
