import React from "react";
import { useNavigate } from "react-router-dom";
import DepartmentLink from "../../components/DepartmentLink/index";
import "./style.scss";

const departments = [
  { name: "html", destination: "/start-exam/html" },
  { name: "css", destination: "/start-exam/css" },
  { name: "javascript", destination: "/start-exam/javascript" },
  { name: "react", destination: "/start-exam/react" },
];

function DepartmentSelection() {
  const navigate = useNavigate();
  const handleSelectDepartment = (departmentId) => {
    navigate(`/start-exam/${departmentId}`);
  };

  return (
    <div className="department-selection">
      <h2>Choose a Department</h2>
      <ul>
        {departments.map((department, index) => (
          <li key={index}>
            <DepartmentLink
              departmentId={department.name}
              index={index + 1}
              destination={department.destination}
              onSelectDepartment={handleSelectDepartment}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DepartmentSelection;
