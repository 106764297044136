import React from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import DepartmentSelection from "./pages/Department";
import UserInfoForm from "./pages/UserInfo";
import Exam from "./pages/Exam";
import ExamFinished from "./pages/ExamFinished";
import FailedExamFinished from "./pages/ExamFinished/FailedExamFinished";
import "./App.css";
import { ExamProvider } from "./components/Context/Context";
import AdminPage from "components/Admin";
import ExamReview from "pages/ExamReview/ExamReview";
import UserHeader from "components/Header/userHeader";

function App() {
  const location = useLocation();

  const excludedPaths = [
    { path: "/exam/:departmentId" },
    { path: "/start-exam/:departmentId" },
    { path: "/exam-finished" },
    { path: "/failed-exam-finished" },
  ];

  const isExamPage = excludedPaths.some((excludedPath) =>
    matchPath(excludedPath.path, location.pathname)
  );

  return (
    <ExamProvider>
      <div className="app">
        {isExamPage ? <UserHeader /> : <Header />}

        <main className="main-app">
          <Routes>
            <Route path="/" element={<DepartmentSelection />} />
            <Route
              path="/start-exam/:departmentId"
              element={<UserInfoForm />}
            />
            <Route path="/exam/:departmentId" element={<Exam />} />
            <Route path="/exam-finished" element={<ExamFinished />} />
            <Route
              path="/failed-exam-finished"
              element={<FailedExamFinished />}
            />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/exam-review" element={<ExamReview />}></Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </ExamProvider>
  );
}

export default App;
