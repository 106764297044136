import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";
import ExamInfo from "../../components/ExamInfo";
import Button from "../../components/Button";
import { isValidEmailFormat } from "../../helpers/isValidEmailFormat";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { ExamContext } from "components/Context/Context";

const UserInfoForm = () => {
  const { departmentId } = useParams();
  const [showExamInfo, setShowExamInfo] = useState(false);
  const emailInputRef = useRef(null);
  const { fullName, setFullName, email, setEmail } = useContext(ExamContext);

  useEffect(() => {
    const storedFullName = localStorage.getItem("fullName");
    const storedEmail = localStorage.getItem("email");
    if (storedFullName) setFullName(storedFullName);
    if (storedEmail) setEmail(storedEmail);
  }, [setEmail, setFullName]);

  const handleNameChange = (e) => {
    setFullName(capitalizeFirstLetter(e.target.value));
    setShowExamInfo(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (isValidEmailFormat(email)) {
      localStorage.setItem("fullName", fullName);
      localStorage.setItem("email", email);
      setShowExamInfo(true);
    }
  };

  return (
    <div className="user-info-container">
      <div className="user-info">
        <h2>Start Exam - Department {departmentId}</h2>
        <form onSubmit={handleFormSubmit}>
          <label>
            Full name:
            <input
              type="text"
              value={fullName}
              onChange={handleNameChange}
              placeholder="Full name"
              required
            />
          </label>
          <br />
          <label>
            Email:
            <input
              ref={emailInputRef}
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              required
            />
          </label>
          <Button type="submit" name="Login" />
        </form>

        {showExamInfo && <ExamInfo />}
      </div>
    </div>
  );
};

export default UserInfoForm;
