import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; 

const firebaseConfig = {
  apiKey: "AIzaSyDugWwkd3xrmwlY0W2ZleIaAAS9kwcNL_4",
  authDomain: "exam-336d9.firebaseapp.com",
  projectId: "exam-336d9",
  storageBucket: "exam-336d9.appspot.com",
  messagingSenderId: "834314350367",
  appId: "1:834314350367:web:16296aed6643a81d4d0a2a",
  measurementId: "G-KJHR34E79K"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app); 

export { app, auth, firestore }; 
