import React, { useState, useEffect, useCallback, useContext } from "react";
import "./style.scss";
import { ExamContext } from "../Context/Context";
import { useParams } from "react-router-dom";

function QuestionModal({ data, onOpen, onClose, questions }) {
  const { departmentId } = useParams();
  const storageKey = `answer_${data.id}_${departmentId}`;
  const storageTextKey = `question_text_${data.id}`;
  const storagePointKey = `question_point_${data.id}`;

  const storedAnswer = localStorage.getItem(storageKey);
  const storedText = localStorage.getItem(storageTextKey);
  const storedPoint = localStorage.getItem(storagePointKey);

  const [isOpen, setIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(!storedAnswer);
  const [answer, setAnswer] = useState(storedAnswer || "");
  const [text, setText] = useState(storedText || data.text || "");
  const [point, setPoint] = useState(storedPoint || data.point || "");
  const { setStoredText } = useContext(ExamContext);

  const getRandomQuestion = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * questions.length);
    return questions[randomIndex];
  }, [questions]);

  useEffect(() => {
    if (storedText) {
      setStoredText(storedText);
    }

    if (data) {
      if (!data.text || !data.point) {
        const randomQuestion = getRandomQuestion();
        const newText = data.text || randomQuestion.text;
        const newPoint = data.point || randomQuestion.point;

        if (!storedText) {
          localStorage.setItem(storageTextKey, newText);
          setText(newText);
        }
        if (!storedPoint) {
          localStorage.setItem(storagePointKey, newPoint);
          setPoint(newPoint);
        }
      } else {
        setText(data.text);
        setPoint(data.point);
      }
      setIsOpen(true);
    }
  }, [
    data,
    point,
    storedText,
    storedPoint,
    getRandomQuestion,
    storageTextKey,
    storagePointKey,
    setStoredText,
  ]);

  const handleAnswer = () => {
    localStorage.setItem(storageKey, answer);
    onOpen(answer, !data.text ? text : null);
  };

  const handleCloseOrReopen = () => {
    setAnswer(storedAnswer || "");
    setIsEditMode(!storedAnswer);
    setIsOpen(!isOpen);
    if (isOpen) {
      onClose();
    }
  };

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleToggleEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSaveEdit = () => {
    localStorage.setItem(storageKey, answer);
    setIsEditMode(false);
  };

  return (
    <div className={`question-modal ${isOpen ? "open" : ""}`}>
      <div className="answer-section">
        <h2>{text}</h2>
        <textarea
          value={answer}
          onChange={handleChange}
          readOnly={!isEditMode}
        />
        <div className="modal-actions">
          {isEditMode ? (
            <button className="edit-button" onClick={handleSaveEdit}>
              Save
            </button>
          ) : (
            <button className="edit-button" onClick={handleToggleEdit}>
              Edit
            </button>
          )}
          <button className="submit-button" onClick={handleAnswer}>
            Submit
          </button>
          <button className="close-button" onClick={handleCloseOrReopen}>
            {isOpen ? "Close" : "Reopen"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestionModal;
