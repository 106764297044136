import React from "react";
import "./style.scss";

const FailedExamFinished = () => {
  return (
    <div className="exam-finished-container">
      <div className="exam-finished">
        <h1>Exam Finished</h1>
        <h3>Unfortunately You failed exam.</h3>
        <h2>Time expired.</h2>
      </div>
    </div>
  );
};

export default FailedExamFinished;
