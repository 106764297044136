import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ExamContext } from "../../components/Context/Context";
import "./style.scss";

const Timer = ({ duration, onTimeout }) => {
  const { timeExpired, setTimeExpired } = useContext(ExamContext);
  const { departmentId } = useParams();

  const getStorageKey = (key) => `department_${departmentId}_${key}`;

  const savedTimeRemaining = parseInt(
    localStorage.getItem(getStorageKey("timeRemaining")),
    10
  );

  const savedTimeExpired =
    sessionStorage.getItem(getStorageKey("timeExpired")) === "true";

  const initialTimeRemaining = savedTimeExpired
    ? 0
    : savedTimeRemaining || duration;
  const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);

  useEffect(() => {
    if (!savedTimeExpired) {
      const timerId = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerId);
            onTimeout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [savedTimeExpired, onTimeout]);

  useEffect(() => {
    if (timeRemaining === 0) {
      setTimeExpired(true);
      sessionStorage.setItem(getStorageKey("timeExpired"), "true");
    } else {
      localStorage.setItem(
        getStorageKey("timeRemaining"),
        timeRemaining.toString()
      );
    }
  }, [timeRemaining, setTimeExpired, departmentId]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="timer-container">
      <div className={`timer ${timeRemaining <= 10 ? "timer-expired" : ""}`}>
        {timeExpired ? (
          <div>{`Time expired: ${departmentId} exam is failed`}</div>
        ) : (
          formatTime(timeRemaining)
        )}
      </div>
    </div>
  );
};

export default Timer;
