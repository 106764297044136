import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../config/firebase";

export async function fetchFinishedExams() {
    try {
        const finishedExamsRef = collection(firestore, "examinees");
        const querySnapshot = await getDocs(finishedExamsRef);
        const examsData = querySnapshot.docs.map(doc => {
            const data = doc.data();
            const questions = Object.keys(data.questions).map(key => ({
                id: key,
                ...data.questions[key]
            }));
            return { id: doc.id, ...data, questions };
        });
        return examsData;
    } catch (error) {
        console.error("Error fetching finished exams: ", error);
        throw error;
    }
}
