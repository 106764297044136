import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../config/firebase";

export async function questionDataFetch(departmentId) {
    
    const questionsCollectionRef = collection(firestore, "departaments", departmentId, "questions");
    const questionsQuery = query(questionsCollectionRef);
    const querySnapshot = await getDocs(questionsQuery);
    const questionsData = [];
    querySnapshot.forEach((doc) => {
        questionsData.push({ id: doc.id, ...doc.data() });
    });

    return questionsData;
}
