import React, { useContext, useEffect, useState } from "react";
import { fetchFinishedExams } from "helpers/finishedExamsFetch";
import ExamReviewModal from "./ExamReviewModal/ExamReviewModal";
import { ExamContext } from "components/Context/Context";
import "./ExamReview.scss";

function ExamReview() {
  const { setOnOpen, setExaminee } = useContext(ExamContext);
  const [exams, setExams] = useState([]);

  const handleOpenModal = (examinee) => {
    setExaminee(examinee);
    setOnOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const examsData = await fetchFinishedExams();
        setExams(examsData);
      } catch (error) {
        console.error("Error fetching exams: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="examinees-card-wrapper">
      {exams.length > 0
        ? exams.map((item) => (
            <div
              onClick={() => handleOpenModal(item)}
              className="examinees-card"
              key={item.id}
            >
              <h2>{item.fullName}</h2>
              <h3>{item.email}</h3>
            </div>
          ))
        : "Wait for exams or chack your network "}

      <ExamReviewModal />
    </div>
  );
}

export default ExamReview;
