import React, { useState, useContext } from "react";
import { ExamContext } from "components/Context/Context";
import emailjs from "emailjs-com";

function ExamReviewModal() {
  const {
    onOpen,
    setOnOpen,
    examinee,
    questionStatus,
    updateQuestionStatus,
    randomPoints,
  } = useContext(ExamContext);
  const [totalPoints, setTotalPoints] = useState(0);

  if (!onOpen || !examinee) return null;

  const handleStatusChange = (questionId, status) => {
    updateQuestionStatus(examinee.id, questionId, status);
  };

  console.log(examinee.questions.map((q) => q.point));
  const calculateTotalPoints = () => {
    let total = 0;
    examinee.questions.forEach((question) => {
      if (questionStatus[examinee.id]?.[question.id] === "correct") {
        total += question.point;
      }
    });
    setTotalPoints(total);
    return total;
  };

  const sendEmail = async () => {
    try {
      const passed = totalPoints >= 25;
      const formattedAnswers = examinee.questions
        .map((question, index) => {
          return `
          Question ${index + 1}: ${question.question}
          Points: ${question.point === null ? randomPoints : question.point}
          Status: ${
            questionStatus[examinee.id]?.[question.id] || "Not reviewed"
          }
        `;
        })
        .join("\n\n");

      const emailContent = {
        to_name: examinee.fullName,
        from_email: "davidgyulinyan@gmail.com",
        message: `
          Exam Review for ${examinee.fullName}
          ----------------------------
          ${formattedAnswers}

          Total Points: ${totalPoints}
          Result: ${passed ? "Passed" : "Failed"}
        `,
      };

      const response = await emailjs.send(
        "service_i4xkk5m",
        "template_orrw58j",
        emailContent,
        "ytaos4pBNcouwx-cb"
      );

      console.log("Email sent successfully:", response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleCompleteReview = async () => {
    try {
      await sendEmail();
      setOnOpen(false);
    } catch (error) {
      console.error("Error completing review:", error);
    }
  };

  return (
    <div className={`exam-review-modal ${onOpen ? "open" : ""}`}>
      <div className="modal-content">
        <button onClick={() => setOnOpen(false)}>Close</button>
        <h2>Review Exam for {examinee.fullName}</h2>
        <h3>Email: {examinee.email}</h3>
        <div className="questions-list">
          {examinee.questions.map((question, index) => (
            <div key={question.id} className="question-item">
              <h4>Question {index + 1}:</h4>
              <p>{question.question}</p>
              <h4>Points: {question.point || randomPoints}</h4>
              <h4>Answer:</h4>
              <p>{question.answer}</p>
              <div className="status-buttons">
                <button
                  className={`correct-button ${
                    questionStatus[examinee.id]?.[question.id] === "correct"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleStatusChange(question.id, "correct")}
                >
                  Correct
                </button>
                <button
                  className={`wrong-button ${
                    questionStatus[examinee.id]?.[question.id] === "wrong"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleStatusChange(question.id, "wrong")}
                >
                  Wrong
                </button>
              </div>
            </div>
          ))}
        </div>
        <button onClick={handleCompleteReview}>Complete</button>
        <button onClick={calculateTotalPoints}>Calculate Total Points</button>
        {<p>Total Points for Correct Answers: {totalPoints}</p>}
      </div>
    </div>
  );
}

export default ExamReviewModal;
