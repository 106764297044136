// DepartmentLink.js

import React from "react";
import { Link } from "react-router-dom";
import ShareableLink from "../ShareLink";
import "./style.scss";

function DepartmentLink({ departmentId, index }) {
  const destination = `/start-exam/${departmentId}`;

  return (
    <div className="department-link">
      <Link to={destination} className="link">
        Department {index}
      </Link>
      <ShareableLink departmentId={departmentId} />
    </div>
  );
}

export default DepartmentLink;
