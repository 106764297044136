import React from "react";
import "./userHeader.scss";
import Animation from "../Animation/index";

const UserHeader = () => {
  return (
    <div className="user-header">
      <Animation />
      <h1>EXAM</h1>
    </div>
  );
};

export default UserHeader;
